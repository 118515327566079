@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --green: #00A389;
  --red: #A65959;
  --black: #101828;
  --white: #FCFCFC;
  --grey: #808080;
  --dark-grey: #667085;
  --stormy: #344054;
  --grey-600: #475467;
  --border-color: #EAECF0;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
}