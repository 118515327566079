@import "../../styles/function";

.add_new {
  &_tab {
    margin: rem(56) rem(54);
    width: rem(691);
  }

  &_top {
    &_upload_video {
      height: rem(126);
      background: var(--white);
      border: rem(1) dashed #eaecf0;
      border-radius: rem(8);
      margin-bottom: rem(16);
      cursor: pointer;

      &_item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: rem(16) rem(24.5);
        column-gap: rem(12);

        .center {
          display: flex;
          align-items: center;
          column-gap: rem(4);

          p {
            font-weight: 600;
            font-size: rem(14);
            color: var(--green);
            margin: 0;
          }

          span {
            font-weight: 400;
            font-size: rem(14);
            color: var(--green);
            margin-top: rem(4);
          }
        }

        p {
          font-weight: 400;
          font-size: rem(12);
          color: var(--green);
          margin: 0;
          padding-top: rem(4);
        }
      }
    }

    &_percentage {
      background: var(--white);
      border: rem(1) solid #eaecf0;
      border-radius: rem(8);
      height: rem(96);
      cursor: pointer;

      &_item {
        display: flex;
        justify-content: space-between;
        padding: rem(16);

        svg {
          cursor: pointer;
        }

        .center {
          display: flex;

          &_item {
            display: flex;
            flex-direction: column;
            column-gap: rem(4);
            margin-left: rem(14);

            p {
              font-weight: 500;
              font-size: rem(14);
              color: var(--stormy);
              margin: 0;
            }

            span {
              font-weight: 400;
              font-size: rem(14);
              color: var(--green);
              margin: 0;
            }
          }
        }
      }
    }
  }

  &_category {
    :global {
      .ant-form-item-required {
        &::before {
          display: none !important;
        }
      }
    }

    margin-top: rem(16);

    .select {
      display: flex;
      align-items: center;
    }
  }

  &_title {
    :global {
      .ant-form-item-required {
        &::before {
          display: none !important;
        }
      }
    }

    margin-top: rem(16);
  }

  &_buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: rem(12);
    width: rem(750);
    border-top: black;
    padding-bottom: rem(20);

    .cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--white);
      border: rem(1) solid var(--grey);
      box-shadow: 0 rem(1) rem(2) rgba(16, 24, 40, 0.05);
      border-radius: rem(8);
      font-weight: 600;
      font-size: rem(14);
      color: var(--grey-600);
      margin: 0;
      padding: rem(10) rem(16);
    }

    .submit {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: rem(10) rem(16);
      margin: 0;
      background: var(--green);
      border: rem(1) solid var(--green);
      box-shadow: 0 rem(1) rem(2) rgba(16, 24, 40, 0.05);
      border-radius: rem(8);
      font-weight: 600;
      font-size: rem(14);
      color: var(--white);

      &:hover {
        color: var(--white);
      }
    }
  }

  :global {
    .quill {
      height: rem(441);
    }
  }
}

.modal {
  :global {
    .ant-modal-content {
      padding: 0 !important;
    }

    .ant-modal-body {
      display: flex;
    }

    .ant-modal-close-x {
      display: none;
    }

    .ant-modal-close {
      display: none;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem(16) rem(26);
  border-bottom: 1px solid #eaecf0;
  top: 0;
  position: sticky;
  z-index: 1000;
  background-color: var(--white);

  &_left {
    display: flex;
    align-items: center;
    column-gap: rem(32);

    svg {
      cursor: pointer;
    }
  }
}
