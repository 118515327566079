@import "../../styles/function";

.add_new {


  &_tab {
    margin: rem(56) rem(54) 0 rem(54);

    &_first {
      :global {
        .ant-form-item-required {
          &::before {
            display: none !important;
          }
        }
      }
    }

    &_second {
      :global {
        .ant-form-item-required {
          &::before {
            display: none !important;
          }
        }
      }
    }
  }

  &_buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: rem(12);
    margin-right: rem(50);

    .cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--white);
      border: rem(1) solid var(--grey);
      box-shadow: 0 rem(1) rem(2) rgba(16, 24, 40, 0.05);
      border-radius: rem(8);
      font-weight: 600;
      font-size: rem(14);
      color: var(--grey-600);
      margin: 0;
      padding: rem(10) rem(16);
    }

    .submit {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: rem(10) rem(16);
      margin: 0;
      background: var(--green);
      border: rem(1) solid var(--green);
      box-shadow: 0 rem(1) rem(2) rgba(16, 24, 40, 0.05);
      border-radius: rem(8);
      font-weight: 600;
      font-size: rem(14);
      color: var(--white);

      &:hover {
        color: var(--white);
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;

    padding: rem(16) rem(26);
    border-bottom: 1px solid #eaecf0;

    &_left {
      display: flex;
      align-items: center;
      column-gap: rem(32);

      svg {
        cursor: pointer;
      }
    }
  }

  .body {
    width: 698px;
  }
}


