@import "../styles/function";

.main_layout {
  display: flex;
  height: 100vh;

  .navbar {
    display: flex;

    flex-direction: column;
    width: 280px;
    padding: rem(32) rem(14);
    border-right: 1px solid var(--border-color);
    box-sizing: border-box;
    height: 100%;

    &_top {
      display: flex;
      align-items: center;
      column-gap: rem(11);
      margin-bottom: rem(41);

      span {
        font-weight: 500;
        font-size: rem(28);
        color: var(--green);
      }
    }

    &_center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: rem(12);

      &_item {
        display: flex;
        align-items: center;
        column-gap: rem(12);
        text-decoration: none;
        padding: rem(8);
        transition: 0.3s;
        border-radius: rem(6);

        &.active {
          background: var(--green);

          svg path {
            stroke: var(--white);
          }

          span {
            color: var(--white);
          }
        }

        span {
          transition: 0.3s;
          font-weight: 600;
          font-size: rem(16);
          color: var(--stormy);
        }

        svg {
          path {
            transition: 0.3s;
          }
        }
      }

      &_item:hover {
        background: var(--green);
        border-radius: rem(6);

        svg {
          path {
            stroke: var(--white);
          }
        }

        span {
          color: var(--white);
        }
      }
    }

    &_bottom {
      margin-top: auto;
      padding-top: rem(10);
      border-top: 1px solid var(--border-color);

      &_item {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: rem(12);
        padding-top: rem(10);
      }

      &_right {
        display: flex;
        flex-direction: column;
        row-gap: rem(5);

        &_top {
          display: flex;
          align-items: center;
          column-gap: rem(55);

          span {
            font-weight: 600;
            font-size: rem(14);
            color: var(--stormy);
          }

          .link {
            margin: 0;
          }
        }

        &_bottom {
          span {
            font-weight: 400;
            font-size: rem(14);
            color: var(--grey-600);
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    border-bottom: 1px solid #eaecf0;
    flex: 1;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;

      &_left {
        display: flex;
        align-items: center;
        column-gap: rem(32);

        svg {
          cursor: pointer;
        }
      }

      &_right {
        &_item {
          text-decoration: none;
        }

        button {
          display: flex;
          column-gap: rem(2);
          width: rem(178);
          height: rem(44);
          align-items: center;
          justify-content: center;
          background: var(--green);
          border: rem(1) solid var(--green);
          box-shadow: 0 rem(1) rem(2) rgba(16, 24, 40, 0.05);
          border-radius: rem(8);

          span {
            font-weight: 600;
            font-size: rem(14);
            color: var(--white);
          }
        }
      }
    }

    .body {
      height: 100vh;
      overflow: auto;
    }
  }
}
