@import "../../styles/function";

.faq {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;

    padding: rem(16) rem(26);
    border-bottom: 1px solid #eaecf0;

    &_left {
      display: flex;
      align-items: center;
      column-gap: rem(32);

      svg {
        cursor: pointer;
      }
    }

    &_right {
      &_item {
        text-decoration: none;
      }

      button {
        display: flex;
        column-gap: rem(2);
        width: rem(178);
        height: rem(44);
        align-items: center;
        justify-content: center;
        background: var(--green);
        border: rem(1) solid var(--green);
        box-shadow: 0 rem(1) rem(2) rgba(16, 24, 40, 0.05);
        border-radius: rem(8);

        span {
          font-weight: 600;
          font-size: rem(14);
          color: var(--white);
        }
      }
    }
  }
  .body {
    margin: rem(24) rem(22);
    border: 1px solid var(--border-color);
    border-radius: rem(6);

    height: rem(613);

    &_title {
      font-weight: 600;
      font-size: rem(20);
      color: var(--stormy);
      padding-left: rem(24);
    }

    &_table {
      &_end {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: rem(18);

        svg {
          cursor: pointer;
        }
      }
    }
  }
}
