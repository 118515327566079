@import "././../../styles/function";

.login {
  display: flex;

  &_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: rem(720);
    height: 100vh;
    margin: 0 rem(300);
    &_top {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: rem(12);
      margin-bottom: rem(32);

      h3 {
        font-weight: 600;
        font-size: rem(30);
        color: var(--black);
        margin: 0;
      }

      p {
        font-weight: 400;
        font-size: rem(16);
        color: var(--grey);
        margin: 0;
      }
    }

    &_bottom {
      display: flex;
      flex-direction: column;
      column-gap: rem(6);

      &_item {
        width: rem(360);
        height: rem(44);
      }

      &_button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00A389;
        border: rem(1) solid var(--green);
        box-shadow: 0 rem(1) rem(2) rgba(16, 24, 40, 0.05);
        border-radius: rem(8);
        color: var(--white);
        width: 360px;
        height: 44px;
        margin-top: rem(40);
      }

      &_button:hover {
        border: 1px solid black;
        color: var(--white) !important;
      }

      :global {
        .ant-form-item-required {
          color: #fff;
        }

        label::before {
          display: none !important;
        }
      }

    }
  }

}